<template>
  <div class="account_login" style="overflow-y: hidden">
    <div class="top">
      <loginHead :loginStatus="loginStatus"></loginHead>
    </div>
    <div class="container">
      <div class="small_logo" @click="toHomePage">
        <img src="@/assets/new_login/small_logo.png" />
      </div>
      <div class="main">
        <main>
          <div class="login_top">
            <!-- <div class="img"> <img src="@/assets/login/flag.png"></div> -->
            <div class="login_title">{{ $t("login.login") }}</div>
          </div>
          <div class="login_change">
            <div :class="lang == 'sp' ? '' : 'item'" @click="curSelect = 1">
              <div class="item_title" :style="{ color: curSelect == 1 ? '#F39800' : '#999999' }">
                {{ $t("login.phoneLogin") }}
              </div>
              <div class="img" v-if="curSelect == 1">
                <!-- <img src="@/assets/login/select.png"> -->
              </div>
            </div>
            <div :class="lang == 'sp' ? '' : 'item'" @click="curSelect = 2">
              <div class="item_title" :style="{ color: curSelect == 2 ? '#F39800' : '#999999' }">
                {{ $t("login.emailLogin") }}
              </div>
              <div class="img" v-if="curSelect == 2">
                <!-- <img src="@/assets/login/select.png"> -->
              </div>
            </div>
          </div>
          <div class="login_input">
            <div class="input_phone" v-if="curSelect == 1">
              <div class="input_img">
                <img src="../../assets/new_login/phone.png" />
              </div>
              <div class="area_code">
                <el-dropdown @command="areaCodeClick">
                  <span class="el-dropdown-link">
                    {{ areaCode
                    }}<i class="el-icon-caret-bottom el-icon--right"></i>
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item :command="item" v-for="(item, index) in areaCodeList" :key="index">{{ item }}
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </div>
              <div class="input">
                <input type="number" :placeholder="$t('login.inputPhone')" v-model="mobile" style="width: 270px" />
              </div>
            </div>

            <div class="input_phone" v-if="curSelect == 2">
              <div class="input_img">
                <img src="../../assets/new_login/email.png" />
              </div>
              <div class="input">
                <input type="text" :placeholder="$t('login.inputEmail')" v-model="user_email" style="width: 320px" />
              </div>
            </div>
            <div class="input_phone">
              <div class="input_img">
                <img src="../../assets/new_login/password.png" />
              </div>
              <div class="input">
                <input style="width: 300px" :type="showPassword ? 'text' : 'password'"
                  :placeholder="$t('login.inputPassword')" v-model="password" />
              </div>
              <div class="is_show" @click="showPassword = !showPassword">
                <img src="../../assets/login/show.png" v-if="showPassword" />
                <img src="../../assets/login/hide.png" v-else />
              </div>
            </div>
          </div>

          <div class="btn" @click="loginClick">
            {{ $t("login.login") }}
          </div>
          <div class="tips">
            <div class="tip_left" @click="btnClick(1)">
              {{ $t("login.registerAccount") }}
            </div>
            <!-- <div class="wechat" @click="scanLogin">
							<img src="../../assets/wechat.png">
						</div> -->
            <div class="tip_right" @click="btnClick(2)">
              {{ $t("login.forgetPassword") }}
            </div>
          </div>
          <div class="quickLogin">
            <div class="border"></div>
            <div class="login_text">{{ $t("login.quickLogin") }}</div>
            <div class="border"></div>
          </div>
          <div class="icon_login">
            <!-- <div @click="loginfb">
              <img
                src="../../assets//new_login/guge.png"
                alt=""
                class="login_icon"
              />
            </div> -->
            <g-signin-button :params="googleSignInParams" @success="onSignInSuccessguge" @error="onSignInErrorguge">
              <img src="@/assets//new_login/guge.png" alt="" class="login_icon" />
            </g-signin-button>

            <!-- <button id="loginBtn" @click="login">login</button> -->

            <div @click="login">
              <img src="@/assets//new_login/facebook.png" alt="" class="login_icon" />
            </div>

            <!-- <fb-signin-button
              :params="fbSignInParams"
              @success="onSignInSuccess"
              @error="onSignInError"
            >
              <img
                src="@/assets//new_login/facebook.png"
                alt=""
                class="login_icon"
              />
            </fb-signin-button> -->
          </div>
        </main>
      </div>
      <!-- <div class=" bottom">
				<loginBottom></loginBottom>
			</div> -->
    </div>
  </div>
</template>

    

<script>
import loginHead from "@/components/loginHead.vue";
import loginBottom from "@/components/loginBottom.vue";

import GoogleSignInButton from "vue-google-signin-button-directive";

export default {
  components: {
    loginHead,
    loginBottom,
  },
  directives: {
    GoogleSignInButton,
  },
  data() {
    return {
      loginStatus: 1, // 判断是否再登录页
      curSelect: 1,
      showPassword: false,

      mobile: "",
      user_email: "",
      code: "",
      password: "",
      areaCodeList: [], //区号数组
      areaCode: "", //选中的区号

      isFlag: false,

      // appId: 'wx00934bd05d3280be', //微信公众号appId
      weiXinCodeUrl: "", //微信二维码地址
      weiXinCode: "", //获取微信的code
      wx_unionid: "",
      wxUserInfor: "", //绑定微信个人信息

      // facebook
      fbSignInParams: {
        scope: "email,user_likes",
        return_scopes: true,
      },
      faceAppid: "613828730446559", //脸书id
      facebookEmail: "",
      facebookId: "",

      lang: "",

      // 谷歌
      clientId:
        "194201949101-44n97qmvv6r53rmus91voqm0incs6220.apps.googleusercontent.com", // 谷歌id

      googleSignInParams: {
        client_id:
          "194201949101-44n97qmvv6r53rmus91voqm0incs6220.apps.googleusercontent.com",
        scope: "email",
        plugin_name: "My First Project",
      },
    };
  },

  mounted() {
    this.lang = localStorage.getItem("lang");

    this.getAreaCode();
    this.enterLogin();
    this.getWeiXinUrl();
    this.weiXinCode = this.$route.query.code;

    // this.weiXinCode = this.$route.query.curWay;
    console.log("微信的code", this.weiXinCode);
    this.init();


    // 监听localstorage里面的数据
    window.addEventListener("setItemEvent", (e) => {
      if (e.key === "lang") {
        this.lang = e.newValue;

        console.log(this.lang);

      }
    });
  },

  watch: {

    curSelect() {
      this.user_email = "";
      this.mobile = "";
      this.code = "";
      this.password = "";
      clearInterval(this.timer);
      this.isGetCode = false;
    },
  },
  methods: {
    // 谷歌登录
    onSignInSuccessguge(googleUser) {
      // `googleUser` is the GoogleUser object that represents the just-signed-in user.
      // See https://developers.google.com/identity/sign-in/web/reference#users
      const profile = googleUser.getBasicProfile(); // etc etc

      console.log(profile, "成功");

      // let googleToken = profile.SY
      // console.log(googleToken,'googleToken');
      if (profile) {
        let data = {
          user_email: profile.mw || profile.lw,
          head_img: profile.iP || profile.hP,
          user_name: profile.zf || profile.yf,
        };
        this.loginGoogle(data);
      }

      // 返回结构
      // wx {SY: '105765040331645235862', yf: '张志信', j_: '志信', zY: '张', hP: 'https://lh3.googleusercontent.com/a/AAcHTtdMfvi2rXOnS43b_3vmwI7pbf5mX5sPFKerI9M7=s96-c', …} '成功'
    },
    onSignInErrorguge(error) {
      console.log("OH NOES", error);
    },

    // 谷歌登录接口 6477eea91a865
    loginGoogle(data) {
      this.$http.loginGoogle(data).then((res) => {
        console.log(res, 123);
        if (res.code == 1) {
          setTimeout(() => {
            // 存储token
            localStorage.setItem("userinfo", JSON.stringify(res.data.userinfo));
            this.$store.commit("edituserinfo", res.data.userinfo);
            this.$router.push({
              path: "/",
            });
          }, 1000);
        } else {
          this.$message.error(res.msg);
        }
      });
    },

    login() {
      let that = this;

      FB.login(function (response) {
        if (
          response.status == "connected" ||
          response.status == "not_authorized"
        ) {
          FB.api("/me?fields=name,first_name,last_name,email", function (ele) {
            // /me为API指定的调用方法，用于获取登陆用户相关信息
            console.log(ele, "2222222222222222");
            that.facebookEmail = ele.email;
            that.facebookId = ele.id;

            let data = {
              faceBookUserId: that.facebookId,
              email: that.facebookEmail,
            };

            that.loginFacebook(data);
          });
        }
      });
    },

    // facebook登录接口
    loginFacebook(data) {
      this.$http.loginFacebook(data).then((res) => {
        console.log(res, 123);
        if (res.code == 1) {
          setTimeout(() => {
            // 存储token
            localStorage.setItem("userinfo", JSON.stringify(res.data.userinfo));
            this.$store.commit("edituserinfo", res.data.userinfo);
            this.$router.push({
              path: "/",
            });
          }, 1000);
        } else {
          this.$message.error(res.msg);
        }
      });
    },

    // // facebook登录登出
    // onSignInSuccess(response) {
    //   switch (response.status) {
    //     case "connected":
    //       console.log("返回第三方FB的信息：授权成功，登录成功！", response);
    //       break;
    //     case "not_authorized":
    //       console.log(
    //         "返回第三方FB的信息：授权成功但是未关联该应用（用户第一次授权）",
    //         response
    //       );
    //       break;
    //     case "unknown":
    //       console.log("返回第三方FB的信息：取消授权", response);
    //       break;
    //   }
    //   // https://developers.facebook.com/docs/graph-api/reference/user
    //   // FB.api('/me?fields=id,name,gender,first_name,last_name,email,birthday,hometown', dude => {
    //   // 	console.log(`Good to see you, ${dude}.`)
    //   // })

    //   // 登录成功回调
    //   FB.api("/me", (dude) => {
    //     console.log(`Good to see you, ${dude.name}.`);
    //   });
    //   console.log(response, "成功回调"); //返回第三方的登录信息 tolen等
    // },
    // onSignInError(error) {
    //   console.log(error, "失败回调");
    //   console.log("返回第三方FB的error信息", error);
    // },

    // 是否有微信的code
    async init() {
      if (this.weiXinCode) {
        await this.getWXUserInfor();
        await this.userSocialLogin();
      }
    },

    // 获取用户信息
    getWXUserInfor() {
      return new Promise((resolve, reject) => {
        let data = {
          code: this.weiXinCode,
        };
        this.$http
          .pc_wx_login(data)
          .then((res) => {
            if (res.code == 1) {
              this.wxUserInfor = res.data;
              this.wx_unionid = this.wxUserInfor.unionid;
            } else {
              this.$message.error(res.msg);
            }
            resolve();
          })
          .catch((v) => {
            console.log(v);
          });
      });
    },

    // 是否三方登录
    userSocialLogin() {
      return new Promise((resolve, reject) => {
        let data = {
          wx_unionid: this.wx_unionid,
          type: 1,
        };
        this.$http.userSocialLogin(data).then((res) => {
          if (res.code == 1) {
            this.$message.success(res.msg);
            // 存储token
            localStorage.setItem("userinfo", JSON.stringify(res.data.userinfo));
            this.$store.commit("edituserinfo", res.data.userinfo);
            this.$router.push({
              path: "/home",
            });
          } else if (res.code == -999) {
            this.$router.push({
              path: "/bindingAccount",
              query: {
                wxUserInfor: this.wxUserInfor,
              },
            });
          } else {
            this.$message.error(res.msg);
          }
          resolve();
        });
      });
    },

    // 获取微信的url
    getWeiXinUrl() {
      this.$http.wechat_qr_code().then((res) => {
        if (res.code == 1) {
          console.log(res.data, "快捷键");
          this.weiXinCodeUrl = res.data;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 扫码登录
    scanLogin() {
      window.location.href = this.weiXinCodeUrl;
    },
    // 按enter键登录
    enterLogin() {
      let that = this;
      document.onkeydown = function (e) {
        e = window.event || e;
        //保证是在登录页面发出的enter事件
        if (
          that.$route.path === "/accountLogin" &&
          (e.code === "Enter" || e.code === "enter")
        ) {
          //调用登录函数
          that.loginClick();
        }
      };
    },
    // 返回首页
    toHomePage() {
      this.$router.push("/");
    },
    toEmail() {
      window.location.href = "http://mail.google.com";
    },

    // 获取区号
    getAreaCode() {
      this.$http
        .getAreaCode()
        .then((res) => {
          if (res.code == 1) {
            this.areaCodeList = res.data;
            this.areaCode = res.data[0];
          } else {
            this.$message.error(res.data.msg);
          }
          console.log(res);
        })
        .catch((v) => {
          console.log(v);
        });
    },

    // 区号点击
    areaCodeClick(command) {
      console.log("8888");
      this.areaCode = command;
    },

    // 去注册页面或者忘记密码页面
    btnClick(val) {
      if (val == 1) {
        this.$router.push({
          path: "/registerAccount",
        });
      } else {
        this.$router.push({
          path: "/forgetPassword",
        });
      }
    },
    // 登录
    loginClick() {
      if (!this.mobile && this.curSelect == 1) {
        this.$message.error(this.$t("tips.mobilePhoneNumberCannotBeEmpty"));
        return;
      }

      if (!this.user_email && this.curSelect == 2) {
        this.$message.error(this.$t("tips.emailNumberCannotBeEmpty"));
        return;
      }

      if (!this.password) {
        this.$message.error(this.$t("tips.passwordCannotBeEmpty"));
        return;
      }

      let data = {
        password: this.password,
        // mobile_code: this.areaCode,
      };
      if (this.curSelect == 1) {
        this.$set(data, "mobile", this.mobile);
        this.$set(data, "mobile_code", this.areaCode);
      } else {
        this.$set(data, "user_email", this.user_email);
      }
      this.$http
        .accountLogin(data)
        .then((res) => {
          if (res.code == 1) {
            this.$message.success(res.msg);
            setTimeout(() => {
              this.isFlag = false;
              // 存储token
              localStorage.setItem(
                "userinfo",
                JSON.stringify(res.data.userinfo)
              );
              this.$store.commit("edituserinfo", res.data.userinfo);
              this.$router.push({
                path: "/",
              });
            }, 1500);
          } else {
            this.isFlag = false;
            this.$message.error(res.msg);
          }
        })
        .catch((v) => { });
    },
  },
};
</script>

<style scoped="" lang="less">
body {
  overflow-y: hidden;
}

.account_login {
  width: 100%;
  height: 100vh;
  background-image: url(../../assets/new_login/bg.png);
  background-repeat: no-repeat;
  background-size: 100% 110%;
}

.container {
  width: 100%;
  margin: 0 auto;
}

.small_logo {
  cursor: pointer;
  margin-top: 42px;
  margin-left: 158px;
  text-align: left;

  img {
    width: 180px;
    height: 44px;
  }
}

main {
  box-sizing: border-box;
  margin-left: 1000px;
  margin-top: 200px;
  width: 560px;
  min-height: 547px;
  padding: 40px 80px;
  // height: 350px;
  background-color: #ffffff;
  box-shadow: 0px 3px 48px rgba(0, 0, 0, 0.08);

  .login_top {
    display: flex;

    .img {
      display: flex;
      align-items: center;

      img {
        width: 24px;
        height: 24px;
      }
    }

    .login_title {
      // margin-left: 8px;
      font-weight: bold;
      font-size: 24px;
    }
  }

  .login_change {
    margin-top: 40px;
    display: flex;
    font-size: 20px;

    .item {
      margin-right: 64px;

      &:hover {
        cursor: pointer;
      }

      .item_title {
        font-weight: 500;
      }
    }

    .img {
      // img {
      // 	height: 11px;
      // }
      width: 40px;
      height: 3px;
      margin: 12px auto 0;
      background: rgba(243, 152, 0);
      opacity: 1;
      border-radius: 2px;
    }
  }

  .login_input {
    margin-top: 32px;
    font-size: 10px;

    .input_phone {
      position: relative;
      display: flex;
      align-items: center;
      border-radius: 24px;
      padding-left: 16px;
      margin-bottom: 16px;
      font-size: 14px;
      width: 400px;
      height: 48px;
      background: rgba(246, 247, 249);
      opacity: 1;
      border-radius: 4px;
      box-sizing: border-box;

      .input_img {
        width: 20px;
        height: 20px;
        padding-right: 16px;

        img {
          width: 20px;
          height: 20px;
        }
      }

      .input {
        padding-left: 5px;

        input {
          border: none;
          outline: none;
          background-color: transparent;
        }

        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
          -webkit-appearance: none !important;
        }
      }
    }

    .is_show {
      position: absolute;
      right: 18px;

      img {
        width: 18px;
        height: 18px;
      }
    }
  }

  .btn {
    width: 400px;
    height: 48px;
    background: rgba(243, 152, 0);
    opacity: 1;
    border-radius: 4px;
    font-size: 16px;
    color: #ffffff;
    line-height: 48px;
    margin-top: 32px;

    &:hover {
      cursor: pointer;
    }
  }

  .tips {
    margin-top: 24px;
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    color: #666666;
    padding: 0 16px;

    &:hover {
      cursor: pointer;
    }
  }

  .quickLogin {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 32px;
    margin-bottom: 16px;

    .border {
      width: 50px;
      height: 0px;
      border: 1px solid #d5d5d5;
      opacity: 1;
    }

    .login_text {
      font-size: 12px;
      padding: 0 16px;
    }
  }

  .icon_login {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 0 30px;

    .login_icon {
      width: 32px;
      height: 32px;
    }
  }
}

.area_code {
  &:hover {
    cursor: pointer;
  }
}

.el-dropdown-menu {
  // width: 100px;
  max-height: 150px !important;
  overflow-y: scroll;
  overflow-x: hidden;
}

/* 定义滚动条样式 */
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: rgba(240, 240, 240, 1);
}

/*定义滚动条轨道 内阴影+圆角*/
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 0px rgba(240, 240, 240, 0.5);
  border-radius: 10px;
  background-color: rgba(240, 240, 240, 0.5);
}

/*定义滑块 内阴影+圆角*/

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 0px rgba(240, 240, 240, 0.5);
  background-color: rgba(240, 240, 240, 0.5);
}

.scan {
  color: #4177dd;
}
</style>
